import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useRevalidator } from "@remix-run/react";
import debounce from "lodash.debounce";
import axios from "axios";

import { IoSearchOutline } from "react-icons/io5";

type SearchResults = Array<{
  id: string;
  name: string;
  slug: string;
}>;

const LocationsSearchBar = (): JSX.Element => {
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const [foundOffices, setFoundOffices] = useState<SearchResults>([]);

  const formRef = useRef<HTMLFormElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  async function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
    if (e) e.preventDefault();
    if (!formRef.current || !searchInputRef.current || searchInputRef.current.value === "") return;

    const response = await axios.get("/api/sub-office/", {
      params: {
        search: formRef.current.search.value,
      },
    });

    setFoundOffices(response.data.data);
  }

  const debouncedHandleChange = debounce(handleSubmit, 700);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === "") {
      setFoundOffices([]);
      return;
    }
    debouncedHandleChange();
  }

  useEffect(() => {}, []);

  return (
    <form
      action="/api/sub-office/"
      className="mr-gutter relative"
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        debouncedHandleChange();
      }}
    >
      <input
        type="search"
        name="search"
        className="pr-15"
        onChange={handleChange}
        ref={searchInputRef}
      />
      <IoSearchOutline className="text-xl absolute top-1/2 -translate-y-1/2 right-7" />
      {foundOffices.length > 0 && (
        <div
          className={`absolute z-1 mt-2 w-full bg-white shadow-md rounded-md top-[100%] max-h-96 overflow-x-hidden overflow-y-auto`}
        >
          {foundOffices.map((office) => {
            return (
              <article key={office.id} className="hover:bg-slate-200">
                <Link
                  className="block w-full h-full px-4 py-2"
                  to={`/locations/${office.slug}/location`}
                  prefetch="intent"
                  onClick={(e) => {
                    e.preventDefault();
                    setFoundOffices([]);
                    navigate(`/locations/${office.slug}/location`);
                    searchInputRef.current?.blur();
                    if (searchInputRef.current) searchInputRef.current.value = "";
                    revalidator.revalidate();
                  }}
                >
                  {office.name}
                </Link>
              </article>
            );
          })}
        </div>
      )}
    </form>
  );
};

export default LocationsSearchBar;
