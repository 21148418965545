import { Link } from "@remix-run/react";
import { UserButton } from "@clerk/remix";

import { IoAddOutline, IoHomeSharp, IoManSharp } from "react-icons/io5";
import { useContext } from "react";
import AppContext from "~/context";
import LocationsSearchBar from "~/components/LocationsSearchBar";

import { MdContactPage, MdList, MdSpaceDashboard } from "react-icons/md";

import "../styles/layout/navbar.css";
import CustomTooltip from "~/components/CustomTooltip";
import { Popover, PopoverContent, PopoverTrigger } from "~/components/ui/popover";
import { Button } from "~/components/ui/Button";

function Navbar({ isNewOfficeLocation }: { isNewOfficeLocation?: boolean }) {
  const appContext = useContext(AppContext);
  if (!appContext) throw new Error("AppContext is null");
  const { user } = appContext;

  return (
    <nav className="navbar">
      <div className="navbar__container container">
        <div className="left flex items-center">
          {user && user.firstName && `Welcome back, ${user?.firstName}!`}
        </div>
        <div className="navbar__right flex items-center">
          {isNewOfficeLocation && <LocationsSearchBar />}
          <CustomTooltip tooltip="Go to home page">
            <Link to="/" className="navbar__link">
              <IoHomeSharp className="navbar__icon text-2xl" />
            </Link>
          </CustomTooltip>
          {user && user?.authorization && user?.authorization === "ADMINISTRATOR" && (
            <CustomTooltip tooltip="Manage Users">
              <Link to="/admin/manage-users?index" className="navbar__link">
                <IoManSharp className="navbar__icon text-2xl" />
              </Link>
            </CustomTooltip>
          )}
          {user &&
            user?.authorization &&
            (user?.authorization === "ADMINISTRATOR" || user?.authorization === "CREATOR") && (
              <CustomTooltip tooltip="Add new office or location">
                <Link to="/new" className="navbar__link">
                  <IoAddOutline className="navbar__icon text-2xl" />
                </Link>
              </CustomTooltip>
            )}
          {user &&
            user?.authorization &&
            (user?.authorization === "ADMINISTRATOR" || user?.authorization === "CREATOR") && (
              <CustomTooltip tooltip="Go to dashboard">
                <Link to="/admin/manage-locations?index" className="navbar__link">
                  <MdSpaceDashboard className="navbar__icon text-2xl" />
                </Link>
              </CustomTooltip>
            )}
          {user &&
            user?.authorization &&
            (user?.authorization === "ADMINISTRATOR" ||
              user?.authorization === "CREATOR" ||
              user.authorization === "USER") && (
              <CustomTooltip tooltip="Create new appointment">
                <Link to="/appointment-form/form?index" className="navbar__link">
                  <MdContactPage className="navbar__icon text-2xl" />
                </Link>
              </CustomTooltip>
            )}
          {user &&
            user.authorization &&
            (user?.authorization === "ADMINISTRATOR" ||
              user?.authorization === "CREATOR" ||
              user?.authorization === "CLIENT" ||
              user.authorization === "USER") && (
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant={"ghost"} size="icon">
                    <MdList className="navbar__icon text-2xl" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="flex flex-col">
                  <Link
                    to="/monitoring/dashboard?index&page=1&pageSize=10"
                    className="hover:font-semibold transition-all"
                  >
                    Appointments Dashboard
                  </Link>
                  <Link
                    to="/monitoring/dashboard/leads?index&page=1&pageSize=10"
                    className="hover:font-semibold transition-all"
                  >
                    Leads Dashboard
                  </Link>
                  <Link to="/monitoring/graphs" className="hover:font-semibold transition-all">
                    Graphs
                  </Link>
                </PopoverContent>
              </Popover>
            )}
          {user &&
            user?.authorization &&
            (user?.authorization === "ADMINISTRATOR" ||
              user?.authorization === "CREATOR" ||
              user?.authorization === "CLIENT" ||
              user?.authorization === "USER") && (
              <div className="navbar__icon text-2xl">
                <UserButton userProfileUrl="/user-profile" userProfileMode="navigation" />
              </div>
            )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
