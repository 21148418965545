import type { AlertProps } from "~/types/alert";
import { useLocation, Link } from "@remix-run/react";
import { useContext } from "react";
import { MdDataSaverOn } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";
import AppContext from "~/context";
import { useAlertMessage } from "~/context/AlertMessageContext";

function setSavingStateWord(isAppSubmitting: boolean, alertMessage: AlertProps) {
  if (isAppSubmitting) return "Saving";
  if (alertMessage.type === "error") return "Error";
  return "Saved";
}

function NewOfficeLocationFloatingBar() {
  const { alertMessage } = useAlertMessage();

  const { isSubmitting, isEditing } = useContext(AppContext) || { isSubmitting: false };

  const location = useLocation();

  const slug = location.pathname.split("/").pop();

  const isAppSubmitting = isSubmitting;

  if (!isEditing) return null;

  const savingStateWord = setSavingStateWord(isAppSubmitting, alertMessage);

  return (
    <div className="fixed top-0 left-0 p-8 z-10">
      <div className="flex items-center gap-2">
        <button
          onClick={() => {
            // Submit the form
            const submitButton = document.querySelector(
              'button[type="submit"]',
            ) as HTMLButtonElement;
            submitButton.click();
          }}
          className="group transition-all relative"
        >
          <MdDataSaverOn
            className={`text-[var(--grey)] text-2xl${
              isAppSubmitting && !alertMessage.message ? " animate-spin" : ""
            }`}
          />
          <span className="group-hover:visible tooltip absolute top-8 -left-1 text-left">
            Click to save.
          </span>
        </button>
        <span className="block mr-2">{savingStateWord}</span>
        <Link to={`/locations/${slug}/location`}>
          <FiExternalLink className="text-primary-500 text-xl" />
        </Link>
      </div>

      <div className="absolute top-0 left-0 w-full h-full bg-white -z-10 opacity-70 rounded-br-lg"></div>
    </div>
  );
}

export default NewOfficeLocationFloatingBar;
