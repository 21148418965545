import Navbar from "./Navbar";
import Footer from "./Footer";
import type { AlertProps } from "~/types/alert";
import { Suspense, lazy } from "react";

import NewOfficeLocationFloatingBar from "./NewOfficeLocationFloatingBar";

import { UserButton } from "@clerk/remix";
import { useAlertMessage } from "~/context/AlertMessageContext";

const Alert = lazy(() => import("../components/error-handling/AlertComponent"));

function Layout({
  children,
  hasNoNavbar,
  message,
  isNewOfficeLocation,
  onlyShowClerkWidget = false,
  hasNoFooter = false,
}: {
  children: JSX.Element[] | JSX.Element;
  hasNoNavbar?: boolean;
  message?: AlertProps;
  isNewOfficeLocation?: boolean;
  onlyShowClerkWidget?: boolean;
  hasNoFooter?: boolean;
}) {
  const { alertMessage } = useAlertMessage();

  return (
    <>
      <Suspense fallback={<div className="hidden"></div>}>
        <Alert
          type={alertMessage.type || message?.type || "success"}
          message={alertMessage.message || message?.message || ""}
          title={alertMessage.title || message?.title || ""}
        />
      </Suspense>
      {isNewOfficeLocation && <NewOfficeLocationFloatingBar />}
      {!hasNoNavbar && <Navbar isNewOfficeLocation={isNewOfficeLocation} />}
      {onlyShowClerkWidget && (
        <div className="fixed top-8 right-8">
          <UserButton />
        </div>
      )}
      <main className="min-h-[82vh]">{children}</main>
      {!hasNoFooter && <Footer />}
    </>
  );
}

export default Layout;
